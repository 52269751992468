import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';

import { useIntl } from 'gatsby-plugin-intl';

import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';

import s from './VoluntaryService.module.scss';

interface IVoluntaryServiceTemplate {
  data: {
    wpVoluntaryService: null | {
      content: string;
    };
  }
}

const VoluntaryServiceTemplate: FC<IVoluntaryServiceTemplate> = (props) => {
  const {
    data: {
      wpVoluntaryService,
    },
  } = props;

  const intl = useIntl();

  let content = '';

  if (wpVoluntaryService !== null) {
    content = wpVoluntaryService.content;
  }

  return (
    <>
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale
        }}
        noindex={true}
        nofollow={true}
        canonical={`https://avionaut.com${getLink(intl.locale, PageType.VOLUNTARY_SERVICE)}/`}
        title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__voluntaryService' })}`}
        description={intl.formatMessage({ id: 'metaDescription__voluntaryService' })}
        metaTags={[
          {
          property: 'og:title',
          content: intl.formatMessage({ id: 'metaTitle__voluntaryService' })
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'metaDescription__voluntaryService' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'metaTitle__voluntaryService' })
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'metaDescription__voluntaryService' })
          },
        ]}
      />
      <div className={s.voluntaryService}>
        <div className={s.voluntaryService__container} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </>
  );
};

export const query = graphql`
  query GetVoluntaryService($locale: String) {
    wpVoluntaryService(locale: {locale: {eq: $locale}}, slug: {eq: "dowolna-akcja"}) {
      content
    }
  }
`;

export default VoluntaryServiceTemplate;
